import _Vue from 'vue';
import { addClass, removeClass } from '@/app/plugins/utils';
import logger from '../logger';
import { ToastType } from '@/lib/components/toast/types';
import TubeError from '@/lib/tube-error';
import * as Sentry from '@sentry/browser';
import { DateTime } from 'luxon';

declare let SENTRY_ENABLED: boolean;

declare module 'vue/types/vue' {
  interface Vue {
    $isEmpty(data?: any): boolean;

    $isMobile(): boolean;

    $addClass(element: any, cls: string): any;

    $removeClass(element: any, cls: string): any;

    $isElementIsInView(element: any, rbuffer: number): any;

    $dateFromUTC(value: string, options?): string;

    $dateTimeFromUTC(value: string, options?): string;

    $dateFromMillis(value: number, options?): string;

    $dateTimeFromUTCDayMonth(value: string, options?): string;

    $msToTime(value, showMillis?): any;

    $relativeTime(value): any;

    $relativeDays(value): any;

    $between(x, min, max): any;

    $copyTextToClipboard(text): any;

    $error(error): void;

    $trackError(error): void;

    $success(msg): void;

    $info(msg): void;

    $capitalize(text): string;

    $firstLetterCapitalize(text): string;

    $validateUrl(url): string;
  }
}

export default function (Vue: typeof _Vue): void {
  Vue.prototype.$isEmpty = (data?: any) => {
    if (!data) {
      return true;
    }
    if (data.constructor === Object) {
      return Object.keys(data).length === 0;
    } else if (data.constructor === Array) {
      return data && data.length === 0;
    }
  };

  Vue.prototype.$isMobile = (function () {
    let check = false;
    (a => {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      ) {
        check = true;
      }
    })(navigator.userAgent || navigator.vendor || window['opera']);
    return check;
  })();

  Vue.prototype.$isElementIsInView = (element: HTMLElement, rbuffer = 0) => {
    if (!element) {
      return;
    }
    const rect = element.getBoundingClientRect();
    const wh = window.innerHeight || document.documentElement.clientHeight;
    const ww = window.innerWidth || document.documentElement.clientWidth;
    return (
      rect.left >= 0 &&
      rect.top >= 0 &&
      rect.left + rect.width + rbuffer <= ww &&
      rect.top + rect.height <= wh
    );
  };

  Vue.prototype.$addClass = addClass;

  Vue.prototype.$removeClass = removeClass;

  Vue.prototype.$between = (x, min, max) => {
    return x >= min && x <= max;
  };

  Vue.prototype.$msToTime = (ms: any, showMillis = false) => {
    const millis = (ms / 1000)
      .toFixed(3)
      .slice(0, -1)
      .split('.')[1];
    let seconds: any = Math.floor((ms / 1000) % 60);
    let minutes: any = Math.floor((ms / (1000 * 60)) % 60);
    const hours: any = Math.floor((ms / (3600 * 1000)) % 3600);
    seconds = seconds < 10 ? '0' + seconds : seconds;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    if (parseInt(hours)) {
      return `${hours}:${minutes}:${seconds}`;
    }
    return showMillis
      ? `${minutes}:${seconds}.${millis}`
      : `${minutes}:${seconds}`;
  };

  Vue.prototype.$relativeTime = (ms: any) => {
    const seconds: any = Math.floor((ms / 1000) % 60),
      minutes: any = Math.floor((ms / (1000 * 60)) % 60),
      hours: any = Math.floor((ms / (3600 * 1000)) % 3600);
    let t = '';
    if (hours) {
      t = `${hours} hour${hours > 1 ? 's' : ''}`;
      minutes && (t = `${t} ${minutes} minute${minutes > 1 ? 's' : ''}`);
      return t;
    }
    minutes && (t = `${minutes} minute${minutes > 1 ? 's' : ''}`);
    return `${t} ${seconds} sec${seconds > 1 ? 's' : ''}`;
  };

  Vue.prototype.$relativeDays = (ms: any) => {
    const diff = ms - Date.now();
    let seconds = diff / 1000
    let minutes = 0
    let hours = 0
    let days = 0;

    if (seconds > 60) {
      minutes = Math.floor(seconds / 60)
      seconds = seconds % 60
    }

    if (minutes > 60) {
      hours = Math.floor(minutes / 60)
      minutes = minutes % 60
    }

    if (hours > 24) {
      days = Math.floor(hours / 24)
      hours = hours % 24
    }

    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ${hours} hour${hours > 1 ? 's' : ''}`
    }
    else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} min${minutes > 1 ? 's' : ''}`
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ${seconds} sec${seconds > 1 ? 's' : ''}`
    }
    else {
      return `${seconds} sec${seconds > 1 ? 's' : ''}`
    }
  };

  Vue.prototype.$dateFromMillis = (data, options?) => {
    if (data) {
      const d1 = DateTime.fromMillis(data, {
        ...options
      });
      return d1.toFormat(
        options && options.format ? options.format : 'MMM dd, yyyy'
      );
    }
    return '';
  };

  Vue.prototype.$dateFromUTC = (value, options?) => {
    if (value) {
      try {
        const dateFormat = new Intl.DateTimeFormat('en', {
          year: 'numeric',
          month: 'long',
          day: '2-digit'
        });
        return dateFormat.format(new Date(value));
      } catch (error) {
        return new Date(value)
          .toString()
          .replace(/\S+\s(\S+)\s(\d+)\s(\d+)\s.*/, '$2-$1-$3');
      }
    }
    throw 'Invalid JSDate';
  };

  Vue.prototype.$dateTimeFromUTC = (value, options?) => {
    if (value) {
      try {
        return new Intl.DateTimeFormat('en', {
          dateStyle: 'long',
          timeStyle: 'long'
        }).format(new Date(value));
      } catch (error) {
        return new Date(value)
          .toString()
          .replace(/\S+\s(\S+)\s(\d+)\s(\d+)\s.*/, '$2-$1-$3');
      }
    }
    throw 'Invalid JSDate';
  };

  Vue.prototype.$dateTimeFromUTCDayMonth = (value, options?) => {
    if (value) {
      try {
        const dateTimeFormat = new Intl.DateTimeFormat('en', {
          month: 'short',
          day: '2-digit'
        });
        return dateTimeFormat.format(new Date(value));
      } catch (error) {
        return value;
      }
    }
    throw 'Invalid JSDate';
  };

  Vue.prototype.$copyTextToClipboard = text => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      const successful = document.execCommand('copy');
      successful ? 'successful' : 'unsuccessful';
    } catch (err) {
      logger.error('error in copying');
    }
    document.body.removeChild(textArea);
  };

  Vue.prototype.$error = error => {
    if (error) {
      const message = error.message || 'Sorry something went wrong.';
      Vue.prototype.$show({
        type: ToastType.Danger,
        message
      });
      if (!(error instanceof TubeError)) {
        logger.error(error);
        if (SENTRY_ENABLED) {
          Sentry.captureException(error);
        }
      }
    }
  };

  Vue.prototype.$trackError = error => {
    if (error) {
      if (!(error instanceof TubeError)) {
        logger.error(error);
        if (SENTRY_ENABLED) {
          Sentry.captureException(error);
        }
      }
    }
  };

  Vue.prototype.$success = msg => {
    if (msg) {
      Vue.prototype.$show({
        type: ToastType.Success,
        message: msg
      });
    }
  };

  Vue.prototype.$info = msg => {
    if (msg) {
      Vue.prototype.$show({
        type: ToastType.Info,
        message: msg
      });
    }
  };

  Vue.prototype.$capitalize = text => {
    if (text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }
  };

  Vue.prototype.$firstLetterCapitalize = text => {
    if (text) {
      return text
        .split('_')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    }
  };

  Vue.prototype.$validateUrl = url => {
    if (!url) {
      return;
    }
    if (url.startsWith('mailto:')) {
      return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i.test(
        url.split(':')[1]
      );
    }
    const pattern = new RegExp(
      '^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$',
      'i'
    );
    return !!pattern.test(url);
  };
}
