export default {
  button: {
    create: 'Create Video',
    new: 'New Video',
    upload: 'Upload',
    create_folder: 'Create Folder',
  },
  tab: {
    dashboard: 'Home',
    videos: 'Videos',
    library: 'Library',
    learning_path: 'Collections',
    learners: 'Learners',
    learner_group: 'Groups',
    academy: 'Academy',
    integrations: 'Integrations',
    hub: 'Knowledge Hub',
    email: 'Emails',
  },
  subtab: {
    academy_home: 'Analytics',
    academy_builder: 'Builder',
    academy_config: 'Configurations',
  },
  actions: {
    invite_team: 'Invite your team',
    workspace_settings: 'Workspace Settings',
  },
  guide: {
    title: 'Quick start guide',
    // title: 'Getting Started'
  },
  workspace: {
    switch: 'Switch Workspace',
  },
};
