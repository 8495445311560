export default {
  button: {
    preview: 'Preview',
    save_n_publish: 'Save & Publish',
    back_to_edit: 'Continue Editing',
    edit: 'Edit Video',
    discard: 'Discard',
    copy_link: 'Copy Link',
    embed_video: 'Embed Video',
    update_settings: 'Update Video',
    delete: 'Delete Video',
    edit_collection: 'Edit Collection',
    edit_title: 'Edit Video Title',
    update_title: 'Update',
    update_description: 'Update Description',
    add_video_description: 'Add video description',
    flow: 'Track Progress',
    private_video: 'Make video private',
    share_settings: 'Share Settings',
    share_n_export: 'Share & Export Options',
    update_video: 'Upload a new version',
  },
  link: {
    go_to_edit: 'Edit video steps',
    back_to_videos: 'Back to all videos',
    back_to_video: 'Back to video',
    back_to_collections: 'Back to all collections',
    back_to_collection: 'Back to collection',
    back_to_player: 'Back',
    back: 'Back',
    add_thumbnail: 'Add thumbnail image',
    signup: 'Sign up for free'
  },
  loading: {
    preview: 'Loading your preview...',
    video: 'Loading your video...'
  },
  thumbnail_settings: {
    label: `Thumbnail Image`,
    description:
      'Uploaded image will be used as thumbnail image in your portal hub',
    update: 'Update'
  },
  publishing: {
    text:
      'Publishing... This might take a minimum of few seconds to a maximum of few minutes.',
    v2_text:
      'Video is getting published. This might take a minimum of few seconds to a maximum of few minutes.',
    link: 'Explore app',
    success: 'Your video has been published successfully',
    info: 'This video has some active changes that are getting published'
  },
  preview: {
    info:
      'This is just a preview. Call-To-Actions, Chapters & Subtitles if any will be displayed in the final published video.',
    v2_info:
      'Please note, the video can be editied even after submission. This is just a preview on how the final video will look like.',
    v2_subtitle_info:
      'Call-To-Actions, Chapters & Subtitles can be further added to the final published video.',
    submit_info:
      'Please note, the video can be editied even after submission. This is just a preview on how the final video will look like. So, please submit and view the final video in case you face issues in preview mode.'
  },
  learning_flow: 'Train your customers on a workflow with videos',
  settings: 'Player Settings',
  collection_settings: 'Collection Settings',
  settings_desc: 'Optimize video controller settings for better navigation',
  collection_settings_desc: 'In-depth analytics on the collection created',
  voice_selection: 'I will go with',
  voice_final: 'Your narration voice',
  bg_selection: 'Background score',
  watermark: 'Watermark settings',
  watermark_label: 'Add logo watermark',
  view_analytics: 'View Analytics',
  select_bgm: 'Select Background Music',
  add_bgm: 'Add Background Music',
  subtitles: {
    title: 'Subtitles / Closed captions (CC)',
    description: 'Select languages',
    languages: {
      english: 'English',
      spanish: 'Spanish',
      portuguese: 'Portuguese',
      french: 'French',
      hindi: 'Hindi',
      thai: 'Thai',
      tamil: 'Tamil',
      kannada: 'Kannada',
      telugu: 'Telugu',
      malayalam: 'Malayalam',
      marathi: 'Marathi',
      german: 'German',
      arabic: 'Arabic',
      malay: 'Malay',
      bengali: 'Bengali',
      vietnamese: 'Vietnamese',
      chinese: 'Chinese',
      polish: 'Polish',
      swedish: 'Swedish',
      danish: 'Danish',
      italian: 'Italian',
      Czech: 'Czech',
      Korean: 'Korean',
    },
    addTag: 'Add a language',
    translate: 'Translate text into %{lang}',
    settings: {
      title: 'Subtitles and closed captions (CC)'
    },
    tooltip_info: "Select this subtitle box to jump to the corresponding timestamp in the video",
    info: "Select the subtitle box on the right to navigate to the corresponding timestamp in the video.",
  },
  references: 'References',
  title: {
    enter: 'Enter Video Name',
    label: 'Video name',
    placeholder: 'How to create a ticket in Jira'
  },
  description: {
    label: 'Video description',
    collection_label: 'Add a description',
    para: "Explain the video in your short version. It's okay to add links.",
    para_collection:
      'Include links and # tags to make your description seo-friendly.',
    para_walkthrough: 'Click on the Edit button to add video description',
    save: 'Save Changes',
    regenarate_button: '🔔 Re-generate description based on the latest change ?',
  },
  quick_jump: {
    title: 'Quick Jump Settings',
    description: 'Allow your audience to easily navigate within the video.',
    label: 'Player Settings (Quick Jump)',
    options: {
      disabled: {
        label: 'Hide quick jumps',
        description: 'Quick jumping spots will disappear from the slider'
      },
      steps: 'Show block level quick jumps',
      chapters: {
        label: 'Show chapter level quick jumps',
        description: 'Useful for longer videos that have informative content.'
      }
    }
  },
  playback_speed: {
    dropdown_text: 'Playback Speed',
    title: 'Video Playback Settings',
    description:
      'Optimize your video viewing by setting a default playback speed'
  },
  permission_settings: {
    access_control: {
      link: 'Anyone with the link can view',
      disabled: 'Only can be viewed within the academy',
      disabled_external: 'Disable external sharing',
      with_strict_auth: 'Can be viewed only after authentication',
      label: 'Privacy Settings',
      restricted: 'Only users with access can view'
    },
    tabs: {
      share: 'Share',
      embed: 'Embed',
      export: 'Export'
    },
    title: 'Share Video',
    learning_path_title: 'Share Collection',
    disable_description:
      'Embedding this video in other applications and sharing video via links is disabled.',
    disable_learning_path_description:
      'Sharing collections via links is disabled.',
    link: {
      title: 'Share via link',
      label: 'Private link',
      description:
        'Anyone with the link can view the content, but will not appear in Google search.'
    },
    gif: {
      title: 'Thumbnail Gif',
      description: 'Embed thumbnail gif within your emails.',
      cta: 'Copy Thumbnail Gif'
    },
    set_password: 'Set Password',
    embed: {
      title: 'Embed Video',
      description:
        'Trainn videos fits perfectly anywhere within your application.',
      modal_title: 'Embed code',
      autoplay: 'Autoplay Video'
    },
  },
  header: {
    active: 'You are in edit mode!',
    active_link: 'View live version',
    publishing: 'Your changes are getting published',
    on_boarded: 'Publish your changes to go live'
  },
  download_images: {
    title: 'Export as Images',
    description: 'Download images for using it in your step by step tutorials.',
    cta: 'Download'
  },
  download_pdf: {
    title: 'Export as Pdf',
    description:
      'Download the generated pdf with the corresponding highlighting and voiceovers.',
    cta: 'Download'
  },
  download_gif: {
    title: 'Export as Gif',
    description:
      'Export first 15 seconds of the video in a gif format',
    cta: 'Download'
  },
  download_audio: {
    title: 'Export Audio',
    description: 'Download the audio of the video',
    cta: 'Download'
  },
  analytics: {
    cta: 'Analytics',
    title: 'Video stats',
    satisfaction: 'Satisfactory Count',
    description: 'The data shown below is updated every 24 hours',
    btn: 'View stats',
    modal: {
      description:
        'The data shown below are the metrics taken for the past 30 days',
      totalViews: 'Total views:',
      uniqueViews: 'Unique views:',
      placeholder:
        'You will see the stats here once viewers have started seeing this video. Go ahead and share this video.',
      likes: 'Likes:',
      dislikes: 'Dislikes:'
    }
  },
  videos: {
    description:
      'Share your video via links, embed them into your application or create an academy of your own.',
    edit: {
      btn: 'Edit'
    },
    delete: {
      link: 'Click to delete this video',
      title: 'Are you sure you want to delete this video?',
      description:
        'On deleting, this video will be moved to trash bin. Any links associated with this video will not be available anymore.',
      delete_btn: 'Yes, Delete it!',
      delete_cta: 'Delete Video',
      cancel: "No, don't delete it"
    },
    restore: {
      link: 'Click to restore this video',
      title: 'Are you sure you want to restore this content?',
      description:
        'On restoring, this content will be restored to its previous state.',
      restore_btn: 'Yes, Restore it!',
      restore_cta: 'Restore Video',
      cancel: "No, don't restore it"
    },
    clone: {
      title: 'Duplicate Video?',
      description:
        'An exact copy of the video will be created. The cloned video will be initially in draft state. You can modify the video if needed and publish it.',
      clone_with_copy: 'Include narration',
      clone_btn: 'Duplicate Video',
      clone_voice_label: 'New narration voice',
      cancel: 'Cancel'
    },
    download_video: {
      title: 'Export Video',
      desc_short: 'Download an mp4 version of your video.',
      description:
        'An mp4 version of your video will be downloaded. We will also email you once your video has been downloaded.',
      cancel: 'Cancel',
      btn: 'Download Video',
      downloading: 'Downloading Video...'
    },
    dt: {
      title: 'Export Transcript',
      vtt_title: 'Export Closed Captions (VTT format)',
      srt_title: 'Export Closed Captions (SRT format)',
      description: 'Your transcript file will be downloaded as CSV',
      vtt_description:
        'Your closed captions file will be downloaded in webvtt format',
      srt_description:
        'Your subtitles file will be downloaded in srt format',
      dt_btn: 'Download Transcript',
      cancel: 'Cancel'
    },
    privacy: {
      title: 'Change video privacy',
      private: 'Private Access',
      private_desc:
        'The videos cannot be shared via links. Only the members who has access will be able to view it in the academy.',
      public: 'Public Access',
      public_desc:
        'Anyone with the link of the video will be able to view the video content.',
      privacy_btn: 'Change video privacy',
      cancel: 'Cancel'
    },
    youtube: {
      title: 'Publish To YouTube',
      cta: 'Publish To YouTube',
      description:
        "Publish the latest version to your '%{channel_name}' youtube channel.",
      description_2: "Based on the privacy status selected above, we will upload the video to YouTube with the title, description and caption/subtitles configured in Trainn.",
      cancel: 'Cancel',
      history_title: 'YouTube - Publish Logs',
      channel: 'Channel',
      video_version: 'Version',
      empty: 'No versions were published to YouTube',
      exists:
        'Current version is either getting published or already been published!'
    },
    deleted: 'This video is no longer available',
    link_deleted: 'This link is no longer valid',
    share_text: 'Like & Follow us on',
    mau_limit_exceeded:
      'This video is no longer available for viewing. Please contact our support team.',
    analytics: 'Video Analytics',
    cc: 'Subtitles & CC',
    cta: 'Add CTA',
    add_chapters: 'Add Chapters'
  },
  group: {
    title: 'Group selected videos',
    description:
      'The selected videos will appear as a group with the given heading in the shareable links and academy'
  },
  advanced_settings: {
    cta: 'Settings'
  },
  generate_link: {
    title: 'Generate Tracking Link',
    description:
      'Send custom links to specific users & attribute engagement',
    long_description:
      'Choose a learner from your workspace or add new learner via email — Generate custom attribution URL for specific learners which will help you track engagement.'
  },
  tabs: {
    description: 'Description',
    more: 'More',
    thumbnail: 'Thumbnail'
  },
  video_engagement: {
    title: 'Video Engagements',
    learner_name: 'Learner Name',
    learner_email: 'Learner Email',
    total_progress: 'Total Progress',
    last_engagement: 'Last Engagement Time'
  },
  guides: {
    guide_embed: {
      title: 'Embed Guide',
      description: 'Trainn guides fits perfectly anywhere within your application',
    },
    clone: {
      title: 'Duplicate Guide?',
      description:
        'An exact copy of the guide will be created. The cloned guide will be initially in draft state. You can modify the guide if needed and publish it.',
      translate: 'Translate guide content',
      clone_btn: 'Duplicate Guide',
      cancel: 'Cancel'
    }
  }
};
