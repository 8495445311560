var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.currentTab
    ? _c(
        "div",
        { staticClass: "t-nav", class: { "t-nav--collapsed": _vm.$isMobile } },
        [
          _c(
            "div",
            {
              staticClass: "t-nav__header",
              class: { "t-mar--r-lg": !_vm.partnerMode && !_vm.$isMobile },
            },
            [
              _vm.partnerMode
                ? _c(
                    "router-link",
                    { staticClass: "t-dis--in-blk", attrs: { to: "/" } },
                    [
                      !_vm.$isMobile
                        ? _c("img", {
                            attrs: {
                              src: require(`@/lib/assets/images/partner/${_vm.partnerId}/logo.svg`),
                              height: "30px",
                            },
                          })
                        : _c("img", {
                            attrs: {
                              src: require(`@/lib/assets/images/partner/${_vm.partnerId}/icon.svg`),
                              height: "30px",
                            },
                          }),
                    ]
                  )
                : _c(
                    "router-link",
                    { staticClass: "t-dis--in-blk", attrs: { to: "/" } },
                    [
                      !_vm.$isMobile
                        ? _c("img", {
                            attrs: {
                              src: require("@/lib/assets/images/trainn-logo-blue.svg"),
                              height: "30px",
                            },
                          })
                        : _c("img", {
                            attrs: {
                              src: require("@/lib/assets/images/Trainn-Logo-Assets/SVG/Trainn-logo-blue.svg"),
                              height: "30px",
                            },
                          }),
                    ]
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "t-nav__main" }, [
            _c(
              "div",
              { staticClass: "t-nav__tabs t-pad--t-mi" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "t-nav__tab",
                    class: {
                      "t-nav__tab--active":
                        _vm.currentTab == _vm.tabs.dashboard,
                    },
                    attrs: { to: "/dashboard" },
                  },
                  [
                    _c("i", {
                      staticClass: "t-nav__icon mdi mdi-home mdi-21px",
                    }),
                    _vm._v(" "),
                    !_vm.$isMobile
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.$t("nav.tab.dashboard"))),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "'t-nav__tab-wrap" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "t-nav__tab",
                        attrs: { to: "/library" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleLibrary()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass:
                            "t-nav__icon mdi mdi-animation-play mdi-21px",
                        }),
                        _vm._v(" "),
                        !_vm.$isMobile
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$t("nav.tab.library"))),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.expandLibrary && !_vm.$isMobile
                          ? _c("i", {
                              staticClass:
                                "t-nav__icon mdi mdi-chevron-down mdi-21px",
                            })
                          : !_vm.$isMobile
                          ? _c("i", {
                              staticClass:
                                "t-nav__icon mdi mdi-chevron-up mdi-21px",
                            })
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c("t-slide", [
                      _vm.expandLibrary
                        ? _c(
                            "div",
                            { staticClass: "t-nav__subtabs" },
                            _vm._l(
                              _vm.$user.workspace.spaces,
                              function (space) {
                                return _c(
                                  "router-link",
                                  {
                                    key: space.id,
                                    staticClass: "t-nav__subtab",
                                    class: [
                                      {
                                        "t-nav__tab--active":
                                          _vm.rootFolderId == space.id,
                                      },
                                    ],
                                    attrs: { to: `/library/${space.id}` },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(space.title) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "t-nav__tab",
                    class: {
                      "t-nav__tab--active":
                        _vm.currentTab == _vm.tabs.collections,
                    },
                    attrs: { to: "/collections" },
                  },
                  [
                    _c("i", {
                      staticClass:
                        "t-nav__icon mdi mdi-view-dashboard mdi-21px",
                    }),
                    _vm._v(" "),
                    !_vm.$isMobile
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.$t("nav.tab.learning_path"))),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: !_vm.$user.can(
                          "update",
                          "video_hub_setting"
                        ) && {
                          content: _vm.$t("message.no_access"),
                          offset: 8,
                        },
                        expression:
                          "!$user.can('update', 'video_hub_setting') && {\n                     content: $t('message.no_access'),\n                     offset: 8,\n                   }\n                   ",
                      },
                    ],
                    staticClass: "t-nav__tab",
                    class: [
                      { "t-nav__tab--active": _vm.currentTab == _vm.tabs.hub },
                      {
                        "t-disabled-opacity t-cursor--not-allow":
                          !_vm.$user.can("update", "video_hub_setting"),
                      },
                    ],
                    attrs: {
                      to: _vm.$user.can("update", "video_hub_setting")
                        ? "/hub"
                        : "",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "t-nav__icon mdi mdi-library mdi-21px",
                    }),
                    _vm._v(" "),
                    !_vm.$isMobile
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("nav.tab.hub")))])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "'t-nav__tab-wrap" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "t-nav__tab",
                        class: [
                          {
                            "t-nav__tab--active":
                              _vm.currentTab == _vm.tabs.academy &&
                              !_vm.showAcademySubTab,
                          },
                          {
                            "t-disabled-opacity t-cursor--not-allow":
                              _vm.$isMobile,
                          },
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.toggleAcademy()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "t-nav__icon mdi mdi-school mdi-21px",
                        }),
                        _vm._v(" "),
                        !_vm.$isMobile
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$t("nav.tab.academy"))),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.showAcademySubTab && !_vm.$isMobile
                          ? _c("i", {
                              staticClass:
                                "t-nav__icon mdi mdi-chevron-down mdi-21px",
                            })
                          : !_vm.$isMobile
                          ? _c("i", {
                              staticClass:
                                "t-nav__icon mdi mdi-chevron-up mdi-21px",
                            })
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c("t-slide", [
                      _vm.showAcademySubTab
                        ? _c(
                            "div",
                            { staticClass: "t-nav__subtabs" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "t-nav__subtab",
                                  class: [
                                    {
                                      "t-nav__subtab--active":
                                        _vm.subTab == "academy_home",
                                    },
                                  ],
                                  attrs: { to: "/academy" },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("nav.subtab.academy_home")
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: !(
                                        _vm.$user.can("update", "courses") ||
                                        _vm.$user.can("update", "academy")
                                      ) && {
                                        content: _vm.$t("message.no_access"),
                                        offset: 8,
                                      },
                                      expression:
                                        "!($user.can('update', 'courses') || $user.can('update', 'academy')) && {\n                           content: $t('message.no_access'),\n                           offset: 8,\n                         }\n                         ",
                                    },
                                  ],
                                  staticClass: "t-nav__subtab",
                                  class: [
                                    {
                                      "t-nav__subtab--active":
                                        _vm.subTab == "academy_builder",
                                    },
                                    {
                                      "t-disabled-opacity t-cursor--not-allow":
                                        _vm.$isMobile ||
                                        !(
                                          _vm.$user.can("update", "courses") ||
                                          _vm.$user.can("update", "academy")
                                        ),
                                    },
                                  ],
                                  attrs: {
                                    to:
                                      ((_vm.$user.can("update", "courses") ||
                                        _vm.$user.can("update", "academy")) &&
                                        "/academy/courses") ||
                                      "",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("nav.subtab.academy_builder")
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: !_vm.$user.can(
                                        "update",
                                        "academy"
                                      ) && {
                                        content: _vm.$t("message.no_access"),
                                        offset: 8,
                                      },
                                      expression:
                                        "!$user.can('update', 'academy') && {\n                           content: $t('message.no_access'),\n                           offset: 8,\n                         }\n                         ",
                                    },
                                  ],
                                  staticClass: "t-nav__subtab",
                                  class: [
                                    {
                                      "t-nav__subtab--active":
                                        _vm.subTab == "academy_config",
                                    },
                                    {
                                      "t-disabled-opacity t-cursor--not-allow":
                                        _vm.$isMobile ||
                                        !_vm.$user.can("update", "academy"),
                                    },
                                  ],
                                  attrs: {
                                    to:
                                      (_vm.$user.can("update", "academy") &&
                                        "/academy/configurations") ||
                                      "",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("nav.subtab.academy_config")
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "t-nav__tab",
                    class: {
                      "t-nav__tab--active": _vm.currentTab == _vm.tabs.learners,
                    },
                    attrs: { to: "/learners" },
                  },
                  [
                    _c("i", {
                      staticClass: "t-nav__icon mdi mdi-account mdi-21px",
                    }),
                    _vm._v(" "),
                    !_vm.$isMobile
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("nav.tab.learners")))])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "t-nav__tab",
                    class: {
                      "t-nav__tab--active":
                        _vm.currentTab == _vm.tabs.learnerGroup,
                    },
                    attrs: { to: "/learner_groups" },
                  },
                  [
                    _c("i", {
                      staticClass: "t-nav__icon mdi mdi-account-group mdi-21px",
                    }),
                    _vm._v(" "),
                    !_vm.$isMobile
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.$t("nav.tab.learner_group"))),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _vm.$user.can("update", "email_setting")
                  ? _c(
                      "router-link",
                      {
                        staticClass: "t-nav__tab",
                        class: [
                          {
                            "t-nav__tab--active":
                              _vm.currentTab == _vm.tabs.emails,
                          },
                        ],
                        attrs: { to: "/emails/settings" },
                      },
                      [
                        _c("i", {
                          staticClass: "t-nav__icon mdi mdi-email mdi-21px",
                        }),
                        _vm._v(" "),
                        !_vm.$isMobile
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$t("nav.tab.email"))),
                            ])
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.partnerMode
                  ? _c(
                      "router-link",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: !_vm.$user.can("update", "workspace") && {
                              content: _vm.$t("message.no_access"),
                              offset: 8,
                            },
                            expression:
                              "!$user.can('update', 'workspace') && {\n                     content: $t('message.no_access'),\n                     offset: 8,\n                   }\n                   ",
                          },
                        ],
                        staticClass: "t-nav__tab",
                        class: [
                          {
                            "t-nav__tab--active":
                              _vm.currentTab == _vm.tabs.integrations,
                          },
                          {
                            "t-disabled-opacity t-cursor--not-allow":
                              !_vm.$user.can("update", "workspace"),
                          },
                        ],
                        attrs: {
                          to: _vm.$user.can("update", "workspace")
                            ? "/integrations"
                            : "",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "t-nav__icon mdi mdi-puzzle mdi-21px",
                        }),
                        _vm._v(" "),
                        !_vm.$isMobile
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$t("nav.tab.integrations"))),
                            ])
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          !_vm.$isMobile ? _c("CelloReferralComponent") : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "t-nav__aside" }, [
            _c(
              "div",
              { staticClass: "t-nav__actions" },
              [
                _c(
                  "router-link",
                  { staticClass: "t-nav__action", attrs: { to: "/settings" } },
                  [
                    _c("div", { staticClass: "t-profile" }, [
                      _c("div", { staticClass: "t-profile__flex" }, [
                        _c("div", { staticClass: "t-profile__shrink" }, [
                          _vm.profile.picture
                            ? _c(
                                "div",
                                {
                                  staticClass: "t-profile__avatar",
                                  class: {
                                    "t-profile__avatar--sm": _vm.$isMobile,
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: { src: _vm.profile.picture },
                                  }),
                                ]
                              )
                            : _vm.initials
                            ? _c(
                                "div",
                                { staticClass: "t-profile__initials" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.initials) +
                                      "\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        !_vm.$isMobile
                          ? _c(
                              "div",
                              { staticClass: "t-profile__grow t-mar--l-xs" },
                              [
                                _vm.profile.fullName
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "t-profile__title t-truncate",
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.profile.fullName) +
                                            "\n              "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "t-profile__caption t-fs--caption t-tc--primary t-tt--capitalize",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.workspace.displayName) +
                                        " Workspace\n              "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "t-nav__action",
                    on: { click: _vm.openLearningCenter },
                  },
                  [
                    _c("i", {
                      staticClass:
                        "t-nav__icon mdi mdi-help-circle-outline mdi-21px",
                    }),
                    _vm._v(" "),
                    !_vm.$isMobile
                      ? _c("span", { staticClass: "t-mar--r-mi" }, [
                          _vm._v(" Help Centre "),
                        ])
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "t-nav__create" },
              [
                _c(
                  "t-button",
                  {
                    attrs: {
                      variant: "primary",
                      tooltip:
                        (!_vm.$user.can("create", "video") &&
                          _vm.$t("message.no_access")) ||
                        "",
                      fluid: "",
                      disabled: !_vm.$user.can("create", "video"),
                      size: _vm.$isMobile && "small",
                    },
                    on: { click: _vm.openCreateModal },
                  },
                  [
                    _vm.$isMobile
                      ? [
                          _c("i", {
                            staticClass: "mdi mdi-motion-play mdi-21px",
                          }),
                        ]
                      : [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("nav.button.create"))),
                          ]),
                        ],
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }