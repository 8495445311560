import Vue from 'vue';

export default class Toast {
  private vm: Vue | null;
  private component: Vue;
  private defaults = {
    id: '',
    title: 'Toast title',
    message: 'Toast body',
    timeout: 5,
    manual: false,
    dismissible: false
  };

  constructor(component: Vue) {
    this.vm = null;
    this.component = component;
  }

  private init() {
    if (this.vm) {
      return;
    }
    this.createPlaceholder();
    this.vm = this.component.$mount('#t-toast');
  }

  private createPlaceholder() {
    const el: HTMLDivElement = document.createElement('div');
    el.setAttribute('id', 't-toast');
    const bodyEl: HTMLBodyElement | null = document.querySelector('body');
    bodyEl && bodyEl.appendChild(el);
  }

  add(params: any) {
    this.init();
    for (const key in this.defaults) {
      if (params[key] === undefined) {
        params[key] = this.defaults[key];
      }
    }

    params.created = Date.now();
    params.id = params.id || Math.random();
    if (!params.manual && !params.dismissible) {
      params.expire = setTimeout(() => {
        this.remove(params.id);
      }, params.timeout * 1000);
    }
    if (this.vm) {
      this.vm.$data.content.unshift(params);
    }
  }

  remove(id) {
    if (this.vm) {
      this.vm.$data.content.splice(this.index(id), 1);
    }
  }

  index(id) {
    if (this.vm) {
      for (const key in this.vm.$data.content) {
        if (id === this.vm.$data.content[key].id) {
          return key;
        }
      }
    }
  }
}
