export const IgnoreNavRoutePath = [
  /^\/videos\/((?:[^\/]+?))\/edit\/recordings(?:\/(?=$))?$/i,
  /^\/videos\/((?:[^\/]+?))\/edit\/preview(?:\/(?=$))?$/i,
  /^\/videos\/v2\/((?:[^\/]+?))\/edit\/preview(?:\/(?=$))?$/i,
  /^\/videos\/((?:[^\/]+?))\/settings(?:\/(?=$))?$/i,
  /^\/videos\/((?:[^\/]+?))\/analytics(?:\/(?=$))?$/i,
  /^\/collections\/((?:[^\/]+?))\/analytics(?:\/(?=$))?$/i,
  /^\/guides\/((?:[^\/]+?))\/analytics(?:\/(?=$))?$/i,
  /^\/academy\/courses\/((?:[^\/]+?))(?:\/(?=$))?$/i,
  /^\/academy\/courses\/((?:[^\/]+?))\/overview\/?$/i,
  /^\/academy\/courses\/((?:[^\/]+?))\/assessment-settings\/?$/i,
  /^\/academy\/courses\/((?:[^\/]+?))\/assessment\/?$/i,
  /^\/academy\/courses\/((?:[^\/]+?))\/certificate\/?$/i,
  /^\/academy\/courses\/((?:[^\/]+?))\/content\/?$/i,
  /^\/academy\/courses\/((?:[^\/]+?))\/lessons\/((?:[^\/]+?))(?:\/(?=$))?$/i,
  /^\/academy\/courses\/((?:[^\/]+?))\/questions(?:\/(?=$))?$/i,
  /^\/academy\/pages\/((?:[^\/]+?))(?:\/(?=$))?$/i,
  /^\/guide\/((?:[^\/]+?))\/edit(?:\/(?=$))?$/i,
  /^\/walkthrough\/((?:[^\/]+?))\/view(?:\/(?=$))?$/i,
  /^\/guides\/((?:[^\/]+?))\/edit(?:\/(?=$))?$/i,
];
export const FullPageRoutePath = [
  /^\/videos\/((?:[^\/]+?))\/edit(?:\/(?=$))?$/i,
  /^\/record$/i,
  /^\/record\/$/i,
  /^\/record_guide$/i,
  /^\/guides\/((?:[^\/]+?))\/edit(?:\/(?=$))?$/i,
  /^\/videos\/v2\/((?:[^\/]+?))\/edit(?:\/(?=$))?$/i,
  /^\/videos\/((?:[^\/]+?))\/settings(?:\/(?=$))?$/i,
  /^\/academy\/courses\/((?:[^\/]+?))(?:\/(?=$))?$/i,
  /^\/academy\/courses\/((?:[^\/]+?))\/overview\/?$/i,
  /^\/academy\/courses\/((?:[^\/]+?))\/assessment-settings\/?$/i,
  /^\/academy\/courses\/((?:[^\/]+?))\/assessment\/?$/i,
  /^\/academy\/courses\/((?:[^\/]+?))\/certificate\/?$/i,
  /^\/academy\/courses\/((?:[^\/]+?))\/content\/?$/i,
  /^\/academy\/courses\/((?:[^\/]+?))\/lessons\/((?:[^\/]+?))(?:\/(?=$))?$/i,
  /^\/academy\/courses\/((?:[^\/]+?))\/questions(?:\/(?=$))?$/i,
  /^\/academy\/pages\/((?:[^\/]+?))(?:\/(?=$))?$/i,
  /^\/integrations\/stripe_callback\/?$/i,
];
export const SearchButtonRoutes = [
  // '/',
  // '/dashboard',
  /^\/library\/((?:[^\/]+?))(?:\/(?=$))?$/i,
  /^\/library(?:\/(?=$))?$/i,
  // '/collections',
  '/learners',
  // '/learner_groups'
];

// export const FullPageRouteNames = [
//   'edit_steps',
//   'edit_video',
//   'login',
//   'sign_up'
// ];
