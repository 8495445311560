
import Vue from 'vue';
import Component from 'vue-class-component';
import Header from '@/app/components/layout/Header.vue';
// import Toast from '@/lib/components/toast/Toast.vue';
import Nav from '@/app/components/layout/Nav.vue';
import User from '@/app/player/controllers/user';
import { AuthService } from '@/app/services/auth-service';
import {
  IgnoreNavRoutePath,
  FullPageRoutePath,
} from '@/app/constants/full-page-routes';
import { Watch } from 'vue-property-decorator';
import { ToastType } from '@/lib/components/toast/types';
import CreateVideoModal from '@/app/components/modals/CreateVideoModal.vue';
import UploadVideoModal from '@/app/components/modals/UploadVideoModal.vue';
import CreateFolderModal from '@/app/components/modals/CreateFolderModal.vue';
import ShowVideoModal from '@/app/components/modals/ShowVideoModal.vue';
import FreshdeskWidget from '@/app/components/FreshdeskWidget.vue';
import IntercomWidget from '@/app/components/IntercomWidget.vue';
import apiClient from './clients/api-client';
const NON_LOGGED_IN_ROUTES = [
  '/sign_up',
  '/login',
  /\/(.*)\/join/,
  '/common_loader',
  '/integrations/youtube_callback',
  '/integrations/intercom_connect',
  '/integrations/intercom_callback',
  '/integrations/intercom_callback_via_app',
];

@Component({
  name: 'app',
  components: {
    Header,
    Nav,
    CreateVideoModal,
    CreateFolderModal,
    UploadVideoModal,
    FreshdeskWidget,
    IntercomWidget,
    ShowVideoModal,
  },
})
export default class App extends Vue {
  private isLoggedInUser = false;
  private isFullPage = false;
  private ignoreNav = false;
  private onBoarding = false;
  private loaded = false;
  private currentCount = 0;
  private exceededCount = 0;
  private overrageCharges = 0;
  private hideHeader = false;
  private controller!: User;
  private edit: any = false;
  private userStatus = '';

  created() {
    return this.preLoadUserPermissions().then(() => {
      this.$user &&
        this.$user
          .loadSubscription()
          .then(() => {
            this.setCurrentUsage();
          })
          .then(() => {
            if (this.$user.trialEnded) {
              AuthService.getInstance().setTrialEnded(true);
              this.$router.push({
                name: 'pricing_page',
              });
            }
          })
          .catch((e) => {
            this.$error(e);
          });
      this.isLoggedInUser = AuthService.getInstance().isLoggedIn;
      const path = window.location.pathname;
      this.onBoarding = path == '/theme';
      this.loaded = true;
      this.isFullPage = FullPageRoutePath.some((route) => path.match(route));
      this.ignoreNav = IgnoreNavRoutePath.some((route) => path.match(route));
    });
  }

  get subscription() {
    return this.$user && this.$user.subscription;
  }

  get activeWorkspaceMemberships() {
    return this.$user && this.$user.activeWorkspaceMemberships;
  }

  get workspace() {
    return this.$user && this.$user.workspace;
  }

  get role() {
    if (
      this.$isEmpty(this.activeWorkspaceMemberships) ||
      this.$isEmpty(this.workspace)
    ) {
      return '';
    }
    const w = this.activeWorkspaceMemberships.filter(
      (w) => w.workspace.id == this.workspace.id
    )[0];
    return w && w.role;
  }

  get canShowNotification() {
    return (
      !this.$isEmpty(this.subscription) &&
      (this.role == 'admin' || this.role == 'owner') &&
      this.subscription.plan_name == 'standard'
    );
  }

  get message() {
    if (!this.canShowNotification) {
      return;
    }
    if (this.subscription.status == 'in_trial') {
      return this.$t('generic.text.in_trial_usage');
    } else if (this.currentCount) {
      return this.exceededCount
        ? this.$t('generic.text.plan_usage_exceeded', {
          current_count: this.currentCount,
          exceeded_count: this.exceededCount,
          overage_charges: this.overrageCharges,
        })
        : this.$t('generic.text.plan_usage', {
          current_count: this.currentCount,
        });
    }
  }

  get hideBody() {
    return !this.$isMobile && this.$route.meta && this.$route.meta.hideBody;
  }

  get canShowLimitExceeded() {
    return this.userStatus == 'in_trial' && this.currentCount >= 75;
  }

  get bannerText() {
    if (this.currentCount >= 100) {
      return this.$t('home.usageLimit.hardWarning.text');
    }
    return this.$t('home.usageLimit.softWarning.text', {
      count: this.currentCount,
    });
  }

  get nonLoggedInRoute() {
    return NON_LOGGED_IN_ROUTES.some((path) => {
      if (path instanceof RegExp) {
        return path.test(window.location.pathname);
      } else {
        return window.location.pathname == path;
      }
    });
  }

  get isLoadingRoute() {
    return window.location.pathname == '/common_loader';
  }

  setCurrentUsage() {
    apiClient
      .currentUsage()
      .then((data) => {
        if (!data) {
          return;
        }
        this.currentCount = data.current_count;
        this.exceededCount = data.exceeded_count;
        this.overrageCharges = data.overage_charges;
        this.userStatus = data.status;
      })
      .catch((e) => {
        this.$error(e);
      });
  }

  @Watch('$route')
  onRouteChange(to) {
    this.isFullPage = FullPageRoutePath.some((route) => to.path.match(route));
    this.ignoreNav = IgnoreNavRoutePath.some((route) => to.path.match(route));
    this.onBoarding = window.location.pathname == '/theme';
  }

  preLoadUserPermissions() {
    const authService = AuthService.getInstance();
    return Promise.resolve()
      .then(() => {
        // if (this.nonLoggedInRoute) {
        //   return Promise.resolve();
        // } else {
        if (authService.isLoggedIn) {
          this.$setUserController(new User());
          return this.$user.load().then(() => {
            const authService = AuthService.getInstance();
            authService.setEmail(
              this.$user.profile.email,
              this.$user.profile.hasPassword
            );
            return Promise.resolve();
          });
        }
        // }
      })
      .catch((error) => {
        if (error.statusCode == 403) {
          authService.logout();
          this.$show({
            type: ToastType.Danger,
            message: this.$t('message.error.unauthorized_login'),
          });
        } else {
          this.$error(error);
        }
      });
  }
}
